<template>
  <div class="userAddress bg-white p-10">
    <AddressTable></AddressTable>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/8/31 17:47
 * @version 1.0
 * @description 用户地址管理
 */
import { userNavOptions } from '@/views/User/userNavOptions'
import AddressTable from '@/views/User/Address/AddressTable'

export default {
  name: 'UserAddress',
  components: { AddressTable },
  data() {
    return {
      userNavOptions,
      shortcutMenus: [{
        titleName: '地址',
        url: '/user/address'
      }]
    }
  },
}
</script>

<style lang="scss" scoped>
.userAddress {
}

.content {
  display: flex;

  .right-content {
    margin-left: 30px;
    width: 100%;
    background-color: #fff;
    justify-content: flex-end;
  }
}
</style>
