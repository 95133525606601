<template>
  <div class="AddressTable">
    <p class="common-tip-title mb-20 mt-10">收货地址</p>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="10">
          <a-col :md="6" :sm="24">
            <a-form-item label="收货人">
              <a-input size="small" v-model="queryParam.userName" placeholder="" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="联系电话">
              <a-input size="small" v-model="queryParam.telNum" placeholder="" />
            </a-form-item>
          </a-col>
          <a-button class="mr-5" type="primary" size="small" @click="$refs.table.refresh(true)">查询</a-button>
          <a-button class="mr-5" type="info" @click="queryParam = {}" size="small">重置</a-button>
          <div style="float: right" class="mr-5">
            <a-button type="primary" @click="handleAdd" size="small">添加</a-button>
          </div>
        </a-row>
      </a-form>

    </div>

    <s-table ref="table" :columns="columns" :data="loadData" :showPagination="'auto'" rowKey="id" bordered size="middle">
      <template slot="address" slot-scope="text, record">
        <div class="infoBox flex-row flex-col-center">
          <span>{{ record.provinceName + record.cityName + record.countyName + record.detailInfo }}</span>
        </div>

      </template>

      <template slot="isDefault" slot-scope="text, record">
        <a-switch :checked="Boolean(record.isDefault)" @change="changeIsDefault(record)" />
      </template>
      <template slot="action" slot-scope="text, record">
        <template v-if="!selectMode">
          <a class="mr-10 " href="#" @click="update(record)">编辑</a>
          <a-popconfirm cancel-text="否" ok-text="是" title="是否确认删除？" @confirm="handleDelete(record)">
            <a href="#" class="text-danger">删除</a>
          </a-popconfirm>
        </template>
        <template>
          <div v-if="selectMode" class="flex-col-center" style="display: flex; justify-content: space-between">
            <a-button size="small" type="primary" @click="$emit('select', record)">选择</a-button>
            <a-button plain size="small" @click="update(record)">编辑</a-button>
            <a-popconfirm cancel-text="否" ok-text="是" title="是否确认删除？" @confirm="handleDelete(record)">
              <a class="text-gary-1" href="#">删除</a>
            </a-popconfirm>
          </div>
        </template>
      </template>
    </s-table>

    <AddressModal v-model="AddressModal" :model="mdl" @ok="() => this.$refs.table.refresh(true)"></AddressModal>
  </div>
</template>

<script>
import { columns } from './AddressTableColumns'
import TabButtonGroup from '@/components/tabButtonGroup'
import { deleteUserAddress, getUserAddress, updateUserAddress } from '@/api/Address'
import AddressModal from '@/views/User/Address/AddressModal'
import STable from '@/components/Table'
/**
 * @author  XuHongli
 * @date  2022/8/31 22:21
 * @version 1.0
 * @description
 */
export default {
  name: 'AddressTable',
  props: {
    selectMode: { // 选择器模式
      type: Boolean,
      default: false
    }
  },
  components: { AddressModal, TabButtonGroup, STable },
  data() {
    return {
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return getUserAddress(requestParameters).then((res) => {
          return res.data
        })
      },
      queryParam: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 暂时无实际意义 Tabs栏值
      TabsValue: '0',
      // 添加/修改 地址弹窗
      AddressModal: false,
      // 传入Modal的数据
      mdl: null,
      // 传入城市接口地址数据
      citys: null
    }
  },
  created() {
    getUserAddress().then((res) => {
      
    })
  },
  methods: {
    changeIsDefault(val) {
      updateUserAddress({ id: val.id, isDefault: !val.isDefault ? 1 : 0 }).then((res) => {
        this.$refs.table.refresh(true)
      })
    },
    handleAdd() {
      this.mdl = null
      console.log('sadasdaffsafsas', this.mdl)
      this.AddressModal = true
    },
    update(val) {
      this.mdl = val
      this.AddressModal = true
    },
    handleDelete(val) {
      deleteUserAddress(val.id).then((res) => {
        this.$refs.table.refresh(true)
        this.$emit('del')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background: unset !important;
}

::v-deep .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background: unset !important;
}

.AddressTable {
  background-color: #fff;
}

::v-deep .ant-table-thead>tr>th {
  background-color: #fafafa;
}

::v-deep .ant-btn-group>.ant-btn:only-child {
  border-radius: unset;
}

.infoBox {
  .Avatar {
    height: 68px;
    width: 68px;
    border-radius: 50%;
    overflow: hidden;
    font-size: 42px;
    line-height: 80px;
    color: white;
    padding-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff4723;

    span {
      margin-left: -10px;
      margin-bottom: 8px;
    }
  }

  .infoData {
    font-size: 16px;
    margin-left: 20px;
    font-weight: 600;
  }
}
</style>
