/**
 * @author  XuHongli
 * @date  2022/8/29 9:52
 * @version 1.0
 * @description 用户中心导航条配置
 */

export const userNavOptions = [
  {
    name: '首页',
    link: '/'
  },
  {
    name: '我的订单',
    link: '/user/order'
  },
  {
    name: '个人中心',
    link: '/user/userCenter'
  },
  {
    name: 'API对接',
    link: '/user/development'
  },
]
